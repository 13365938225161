<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="comment-box">
          <div id="SOHUCS" :sid="photoEtag"></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Comment",
  props: ["photoEtag"],
  mounted() {
    this.$nextTick(() => {
      const appid = "cyvCn9gfc";
      const conf = "prod_70001fed5ba6e46e51eb28b67982ca91";
      this.loadJs("https://changyan.sohu.com/upload/changyan.js", function () {
        window.changyan &&
          window.changyan.api.config({ appid: appid, conf: conf });
      });
    });
  },
  methods: {
    loadJs(url, cb) {
      window.changyan = undefined;
      window.cyan = undefined;
      try {
        const c =
          document.getElementsByTagName("head")[0] ||
          document.head ||
          document.documentElement;
        const b = document.createElement("script");
        b.setAttribute("type", "text/javascript");
        b.setAttribute("charset", "UTF-8");
        b.setAttribute("src", url);
        b.setAttribute("id", "changyan_pc_js");
        if (window.attachEvent) {
          b.onreadystatechange = function () {
            const e = b.readyState;
            if (e === "loaded" || e === "complete") {
              b.onreadystatechange = null;
              cb && cb();
            }
          };
        } else {
          if (cb) {
            b.onload = cb;
          }
        }
        c.appendChild(b);
      } catch (error) {
        cb && cb();
      }
    },
    onDestroy() {
      console.log("comment destorying...");
      try {
        const removeRep = /^http(s)?:\/\/changyan\./;
        const $head =
          document.getElementsByTagName("head")[0] ||
          document.head ||
          document.documentElement;
        const $script = $head.querySelectorAll("script");
        $script.forEach((item) => {
          const src = item.getAttribute("src");
          if (src && removeRep.test(src)) {
            $head.removeChild(item);
          }
        });
        for (const key in window) {
          if (/^(changyan(\d)?|cyan)/.test(key)) {
            window[key] = undefined;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  destroyed() {
    this.onDestroy();
  },
};
</script>
<style scoped>
</style>
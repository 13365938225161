<template>
  <v-dialog v-model="isOpen" :overlay-opacity="0.8">
    <v-img
      max-height="90vh"
      contain
      :src="getSrc('full')"
      :lazy-src="getSrc('compressed')"
      @click="isOpen = false"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row> </template
    ></v-img>
  </v-dialog>
</template>

<script>
export default {
  name: "PhotoDetail",
  props: ["photo"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    getSrc(size) {
      if (this.photo !== undefined && this.photo !== null) {
        let baseURL = this.bucketURL + this.photo.key;
        if (size === "full") {
          return baseURL;
        } else {
          return baseURL + "?x-oss-process=image/resize,w_100,m_lfit";
        }
      }

      return "";
    },
  },
};
</script>


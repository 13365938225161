<template>
  <v-container>
    <div class="text-center mt-4 mb-4">
      <ContactMe />
      <p class="text-caption text--disabled mb-0">CopyRight ©2021 Dusai</p>
      <p class="text-caption text--disabled">
        <a href="https://beian.miit.gov.cn/" style="color: grey"
          >蜀ICP备18018263号-2</a
        >
      </p>
    </div>
  </v-container>
</template>

<script>
import ContactMe from "../components/ContactMe.vue";

export default {
  name: "Footer",
  components: {
    ContactMe,
  },
  methods: {
    mailTo() {
      window.location.href =
        "mailto:dusaiphoto@foxmail.com?subject=Photo%20Communication&body=Hi!";
    },
  },
};
</script>

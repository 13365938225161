<template>
  <v-container>
    <v-row>
      <v-col v-for="photo in photos" :key="photo.key" cols="12" xl="3" md="4" sm="6">
        <PhotoCard :initialPhoto="photo" v-on:clickPhoto="showDetail" />
      </v-col>
    </v-row>
    <div v-intersect.quiet="fetchNewPhotos"></div>

    <NavButton v-on:onToggleLocationList="toggleLocationList" />
    <LocationList
      :dialog="locationListOpen"
      v-on:itemChanged="loctionItemChanged"
      v-on:onRemove="removeLocation"
      ref="locationList"
    />
    <PhotoDetail :photo="selectedPhoto" ref="photoDetail" />
  </v-container>
</template>

<script>
import axios from "axios";
import PhotoCard from "./PhotoCard.vue";
import NavButton from "./NavButton.vue";
import LocationList from "./LocationList.vue";
import PhotoDetail from "./PhotoDetail.vue";

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export default {
  name: "Body",
  components: {
    PhotoCard,
    NavButton,
    LocationList,
    PhotoDetail,
  },

  data: () => ({
    photos: [],
    fetching: false,
    locationListOpen: false,
    location: null,
    selectedPhoto: null,
  }),
  methods: {
    showDetail(photo) {
      this.selectedPhoto = photo;
      if (this.selectedPhoto !== null && this.selectedPhoto !== undefined) {
        this.$refs.photoDetail.isOpen = true;
      }
    },
    // 地点对话框选项改变
    loctionItemChanged(item) {
      // this.toggleLocationList();
      this.photos = [];
      this.location = item;
      this.fetchNewPhotos();
      this.$refs.locationList.onClosePanel();
    },
    // 打开地点对话框事件
    toggleLocationList() {
      this.locationListOpen = !this.locationListOpen;
    },
    removeLocation() {
      this.photos = [];
      this.location = null;
      this.fetchNewPhotos();
      this.$refs.locationList.onClosePanel();
    },
    // 请求照片数据
    fetchNewPhotos() {
      if (!this.fetching) {
        this.fetching = !this.fetching;

        let url =
          this.location === null
            ? "/api/photo"
            : "/api/photo/location?location=" + this.location;

        axios
          .get(url)
          .then((response) => {
            let photoKeys = this.photos.map((obj) => obj.key);
            var newPhotos = [];
            for (let newPhoto of response.data.photos) {
              if (!photoKeys.includes(newPhoto.key)) {
                newPhotos.push(newPhoto);
              }
            }
            this.photos = [...this.photos, ...newPhotos];
          })
          .finally(() => {
            sleep(10).then(() => {
              this.fetching = !this.fetching;
            });
          });
      }
    },
  },
  mounted() {
    this.fetchNewPhotos();
  },
};
</script>

<template>
  <div ref="card">
    <v-card :loading="loading" class="mx-auto" max-width="400">
      <a @click="onClickPhoto">
        <v-img
          height="250"
          class="white--text align-end"
          :src="bucketURL + photo.key + compress400"
          :lazy-src="
            bucketURL + photo.key + '?x-oss-process=image/resize,w_100,m_lfit'
          "
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <v-card-title class="pb-0" v-if="hasTitle">{{
            photo.title
          }}</v-card-title>
          <v-card-text class="mt-0" v-if="createdDate !== undefined">{{
            createdDate
          }}</v-card-text>
        </v-img>
      </a>

      <!-- <v-card-title v-if="hasTitle">
        {{ photo.title }}
      </v-card-title> -->

      <v-card-text class="pb-2">
        <v-row align="center" class="mx-0 mt-0">
          <v-rating
            :value="ratePoint.point"
            color="amber darken-1"
            background-color="grey"
            dense
            half-increments
            readonly
            :size="20"
          ></v-rating>

          <div class="grey--text ms-2">
            {{ ratePoint.point }} ({{ ratePoint.count }})
          </div>

          <v-icon
            class="ml-2 grey--text"
            @click="openEditingDialog"
            v-if="isSuperuser()"
            >mdi-pencil-box</v-icon
          >
        </v-row>

        <div class="mt-4 mb-1 font-weight-bold">
          <v-icon small color="grey darken-1">mdi-map-marker</v-icon>
          {{ location }}
        </div>

        <div class="ml-1 mt-1">
          {{ photo.desc }}
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions class="mb-3 mt-0">
        <v-btn text color="grey darken-1" @click="onReveal"> EXIF </v-btn>

        <v-btn color="grey darken-1" text @click="gotoDetailPage">
          评论区
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          icon
          :color="thumbDown ? 'green' : 'grey'"
          @click="onLike(false)"
        >
          <v-icon>mdi-thumb-down</v-icon>
        </v-btn>
        <div :style="{ color: thumbDown ? 'green' : 'grey' }" class="mr-2">
          {{ photo.unlikes }}
        </div>

        <v-btn icon :color="thumbUp ? 'red' : 'grey'" @click="onLike(true)">
          <v-icon>mdi-thumb-up</v-icon>
        </v-btn>
        <div :style="{ color: thumbUp ? 'red' : 'grey' }">
          {{ photo.likes }}
        </div>
      </v-card-actions>

      <v-expand-transition>
        <v-card
          v-if="reveal"
          class="transition-fast-in-fast-out v-card--reveal"
          style="height: 100%"
          @click="reveal = false"
          :ripple="false"
        >
          <v-simple-table :height="tableHeight" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Attribute</th>
                  <th class="text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, name) in exifs" :key="name">
                  <td>{{ name }}</td>
                  <td>{{ value.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-expand-transition>

      <PhotoEditDialog
        :initialPhoto="photo"
        v-on:submitDone="submitDone"
        ref="editingDialog"
      />
      <SnackBar ref="snack" duration="2000" text="更新完成" />
      <SnackBar ref="likeRate" :duration="2000" text="感谢您的点评" />
    </v-card>
  </div>
</template>


<script>
import axios from "axios";
import PhotoEditDialog from "./PhotoEditDialog.vue";
import SnackBar from "./SnackBar.vue";

export default {
  name: "PhotoCard",
  components: { PhotoEditDialog, SnackBar },

  props: ["initialPhoto"],

  data: function () {
    return {
      loading: false,
      selection: 1,
      photo: this.initialPhoto,
      storageName: this.initialPhoto.key + ".galary",
      thumbUp: false,
      thumbDown: false,
      reveal: false,
      tableHeight: 398,
      exifs: [],
      // comments: [],
    };
  },
  mounted() {
    axios
      .get(this.bucketURL + this.photo.key + this.exifSuffix)
      .then((response) => {
        this.exifs = response.data;
      });
    this.checkThumbColor();

    // axios
    //   .get(
    //     "api/photo/comments?" +
    //       "client_id=" +
    //       this.changyanID +
    //       "&topic_url=/" +
    //       this.photo.etag +
    //       "&topic_source_id=" +
    //       this.photo.etag
    //   )
    //   .then((response) => {
    //     this.comments = response.data;
    //   });
  },

  methods: {
    isSuperuser() {
      return localStorage.getItem("access.galary") !== null;
    },
    httpToHttps(url) {
      return url.replace(/http:/, "https:");
    },
    gotoDetailPage() {
      let routeUrl = this.$router.resolve({
        name: "Detail",
        params: { id: this.photo.etag },
        query: { key: this.photo.key },
      });
      window.open(routeUrl.href, "_blank");
    },
    onReveal() {
      this.reveal = true;

      this.tableHeight = this.$refs.card.clientHeight;
    },
    onClickPhoto() {
      this.$emit("clickPhoto", this.photo);
    },
    onLike(flag) {
      let value = localStorage.getItem(this.storageName);
      let data = {};
      data.key = this.photo.key;
      data.etag = this.photo.etag;

      if (flag === true) {
        if (value === null) {
          data.like = 1;
          data.unlike = 0;
          localStorage.setItem(this.storageName, "1");
        } else if (value === "1") {
          data.like = -1;
          data.unlike = 0;
          localStorage.removeItem(this.storageName);
        } else {
          data.like = 1;
          data.unlike = -1;
          localStorage.setItem(this.storageName, "1");
        }
      } else {
        if (value === null) {
          data.like = 0;
          data.unlike = 1;
          localStorage.setItem(this.storageName, "-1");
        } else if (value === "-1") {
          data.like = 0;
          data.unlike = -1;
          localStorage.removeItem(this.storageName);
        } else {
          data.like = -1;
          data.unlike = 1;
          localStorage.setItem(this.storageName, "-1");
        }
      }

      axios
        .post("api/photo/like/", data)
        .then((response) => {
          this.photo = response.data.photo;
        })
        .catch((error) => console.log(error));
      this.checkThumbColor();
      this.$refs.likeRate.isOpen = true;
    },
    checkThumbColor() {
      let value = localStorage.getItem(this.storageName);
      if (value === null) {
        this.thumbUp = false;
        this.thumbDown = false;
      } else if (value === "1") {
        this.thumbUp = true;
        this.thumbDown = false;
      } else {
        this.thumbUp = false;
        this.thumbDown = true;
      }
    },
    openEditingDialog() {
      this.$refs.editingDialog.$data.editing = true;
    },
    submitDone(photo) {
      this.photo = photo;
      this.$refs.snack.open();
    },
  },

  computed: {
    firstTenComments: function () {
      if (this.comments.comments !== undefined) {
        return this.comments.comments.slice(0, 10);
      }

      return [];
    },
    createdDate: function () {
      if (this.exifs.DateTime !== undefined) {
        let date = this.exifs.DateTime.value.split(" ")[0];

        if (date[0] !== undefined) {
          let components = date.split(":");
          let year, month, day;
          [year, month, day] = [components[0], components[1], components[2]];

          return year + "年" + month + "月" + day + "日";
        }
      }

      return null;
    },
    hasTitle: function () {
      return this.photo.title === "" ? false : true;
    },
    location: function () {
      var location = this.photo.location;
      if (location === "") {
        location = this.photo.key.split("/")[0];
      }

      return location;
    },
    ratePoint: function () {
      let point;

      let likes = this.photo.likes;
      let unlikes = this.photo.unlikes;
      if (likes === 0 && unlikes === 0) {
        point = 2.5;
      } else if (likes === 0) {
        point = 0;
      } else if (unlikes === 0) {
        point = 5;
      } else {
        point = 5 * (likes / (likes + unlikes));
      }

      return { point: Number(point.toFixed(1)), count: likes + unlikes };
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
<template>
  <div>
    <v-dialog v-model="isOpen" scrollable max-width="500px">
      <v-card>
        <v-card-title>地点</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list flat>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="dateFormat(item.created) + ' - ' + item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="text-center ma-2">
      <v-snackbar top v-model="snackbar" :timeout="-1">
        摄影地点: {{ selectedLocation }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="onClose">
            清除
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LocationList",
  props: ["dialog"],
  data() {
    return {
      isOpen: false,
      selectedItem: undefined,
      items: [],
      snackbar: false,
    };
  },
  methods: {
    onClose() {
      this.snackbar = false;
      this.selectedItem = undefined;
    },
    onClosePanel() {
      this.isOpen = false;
    },
    dateFormat(dateStr) {
      let date = dateStr.split(" ")[0];

      if (date[0] !== undefined && date[0] !== "") {
        let components = date.split(":");
        let year, month;
        [year, month] = [components[0], components[1]];

        return year + "." + month;
      }

      return "";
    },
    sortItemsByCreated() {
      this.items.sort((first, second) => {
        return first.created > second.created ? -1 : 1;
      });
    },
  },
  computed: {
    selectedLocation: function () {
      return this.selectedItem === undefined
        ? null
        : this.items[this.selectedItem].text;
    },
  },
  watch: {
    dialog: function () {
      this.isOpen = true;
      this.sortItemsByCreated();
    },
    selectedItem: function (newValue) {
      let param = this.selectedLocation;
      this.snackbar = param === null ? false : true;
      if (newValue !== undefined) {
        this.$emit("itemChanged", param);
      } else {
        this.$emit("onRemove");
      }
    },
  },
  mounted() {
    axios.get("/api/photo/dir").then((response) => {
      for (let location of response.data.locations) {
        axios
          .get(this.bucketURL + location.first_obj_key + this.exifSuffix)
          .then((response) => {
            let data = {
              text: location.prefix.replace("/", ""),
              icon: "mdi-flag",
              created:
                response.data.DateTime !== undefined
                  ? response.data.DateTime.value
                  : "0",
            };
            this.items = [...this.items, ...[data]];
          });
      }
    });
  },
};
</script>
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false
// oss bucket url
Vue.prototype.bucketURL = "https://galary.oss-cn-beijing.aliyuncs.com/"
// oss 缩略图 400*xxx
Vue.prototype.compress400 = '?x-oss-process=image/resize,w_400,m_lfit'
// oss photo exifs suffix
Vue.prototype.exifSuffix = "?x-oss-process=image/info"

// 畅言 id
Vue.prototype.changyanID = 'cyvCn9gfc'


// String -> HashCode
String.prototype.hashCode = function (seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < this.length; i++) {
    ch = this.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

String.prototype.hashCodeV2 = function () {
  // 默认长度 24
  return this.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
};

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center" class="mt-16">
        <v-avatar size="180">
          <v-img src="https://galary-resources.oss-cn-beijing.aliyuncs.com/galary-title.jpg"></v-img>
        </v-avatar>
        <div class="text--secondary mt-6 mb-1 mashanzheng-font fontSize-28">
          杜赛的相册
        </div>
        <div class="text--disabled mb-8 mashanzheng-font">成都土著业余摄影师</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  prots: ["photo"],

  data: () => ({}),
};
</script>

<style scoped>
.mashanzheng-font {
  font-family: "Ma Shan Zheng", cursive;
  font-style: normal;
  font-weight: 400;
}

.fontSize-28 {
  font-size: 28px;
}
</style>

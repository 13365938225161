<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card class="mx-auto my-12" max-width="374">
              <v-img
                height="250"
                :src="bucketURL + photoURL + compress400"
                class="white--text align-end"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <Comment v-if="photoEtag !== null" :photoEtag="photoEtag" />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from "../components/Footer.vue";
import Comment from "../components/Comment.vue";

export default {
  name: "Detail",

  components: {
    Footer,
    Comment,
  },
  methods: {},

  data: () => ({
    photoURL: null,
    photoEtag: null,
  }),
  mounted() {
    this.photoEtag = this.$route.params.id;
    this.photoURL = this.$route.query.key;
  },
};
</script>
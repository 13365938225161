<template>
  <v-app>
    <v-main>
      <Header />
      <Body />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Header from "../components/Header.vue";
import Body from "../components/Body.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Body,
    Footer,
  },
  methods: {},

  data: () => ({}),
};
</script>
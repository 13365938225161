<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/login">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {},
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      console.log(to, from);
    },
  },
  data: () => ({}),
};
</script>
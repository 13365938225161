<template>
  <div>
    <v-speed-dial
      fixed
      right
      bottom
      v-model="fab"
      direction="top"
      :open-on-hover="false"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-reorder-horizontal </v-icon>
        </v-btn>
      </template>

      <v-btn fab dark small color="green darken-1" @click="mailTo">
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
        @click="$emit('onToggleLocationList')"
      >
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>

      <v-btn
        fab
        dark
        small
        color="red"
        @click="$vuetify.goTo(0, { duration: 1000 })"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>


<script>
export default {
  name: "NavButton",
  components: {},
  data: () => ({
    fab: false,
  }),
  methods: {
    mailTo() {
      window.location.href =
        "mailto:dusaiphoto@foxmail.com?subject=Photo%20Communication&body=Hi!";
    },
  },

  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>

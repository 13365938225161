<template>
  <v-row justify="center">
    <v-dialog v-model="editing" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="标题" v-model="photo.title"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="地点"
                  v-model="photo.location"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  counter
                  label="描述"
                  v-model="photo.desc"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "PhotoEditDialog",
  props: ["initialPhoto"],
  data: function () {
    return {
      editing: false,
      photo: this.initialPhoto,
    };
  },
  methods: {
    submit() {
      let that = this;
      that.editing = false;

      let data = {};
      data.title = that.photo.title;
      data.location = that.photo.location;
      data.desc = that.photo.desc;
      data.key = that.photo.key;
      data.etag = that.photo.etag;

      axios
        .post("/api/photo/edit/", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access.galary"),
          },
        })
        .then(() => {
          this.$emit("submitDone", that.photo);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<template>
  <v-snackbar
    color="amber"
    shaped
    light
    bottom
    v-model="isOpen"
    :timeout="duration"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  props: ["duration", "text"],
  data: () => ({
    isOpen: false,
  }),
  methods: {
    open() {
      this.isOpen = true;
    },
  },
};
</script>
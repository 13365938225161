<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        v-bind="attrs"
        v-on="on"
        text
        dark
        color="grey"
        @click="mailTo()"
      >
        <v-icon dark class="mr-2"> mdi-email </v-icon> Contact me
      </v-btn>
    </template>
    <span>dusaiphoto@foxmail.com</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ContactMe",

  methods: {
    mailTo() {
      window.location.href =
        "mailto:dusaiphoto@foxmail.com?subject=Photo%20Communication&body=Hi!";
    },
  },
};
</script>
